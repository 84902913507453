<template>
  <div>
    <div class="app-container">
      <el-row
          style="padding-bottom: 15px"
          type="flex"
          align="middle"
          justify="space-between"
        >
          <el-col :span="3">
            <b>考核汇总</b>
          </el-col>

        <el-col :span="3" :pull="1"  >
<!--          <el-upload-->
<!--            action="/上传文件的接口"-->
<!--            :on-change="onChange"-->
<!--            :auto-upload="false"-->
<!--            :show-file-list="false"-->
<!--            limit=1-->
<!--            accept=".xls, .xlsx" >-->
<!--            <el-button type="warning" icon="el-icon-folder-add">导入职业道道学时</el-button>-->
<!--          </el-upload>-->

          <el-upload
            ref="upload"
            class="upload-demo"
            action="lalaa"
            :on-change="handleChange"
            :limit= 1
            accept=".xls, .xlsx"
            :auto-upload="false">
            <el-button size="small" type="warning" icon="el-icon-upload2">导入职业道德学时</el-button>
          </el-upload>
        </el-col>

        <el-col :span="3" :pull="1">
          <el-upload
            ref="upload_other"
            class="upload-demo"
            action="lalaa"
            :show-file-list="true"
            :on-change="handleChange_other"
            :limit= 1
            accept=".xls, .xlsx"
            :auto-upload="false">
            <el-button size="small" type="warning" icon="el-icon-upload2">导入培训学时</el-button>
          </el-upload>
        </el-col>

        <el-col :span="3" :pull="1">
          <el-upload
            ref="upload_free"
            class="upload-demo"
            action="lalaa"
            :show-file-list="true"
            :on-change="handleChange_free"
            :limit= 1
            accept=".xls, .xlsx"
            :auto-upload="false">
            <el-button size="small" type="warning" icon="el-icon-upload2">导入公益学时</el-button>
          </el-upload>
        </el-col>



        <el-col :span="3" :pull="1" >
          <el-button type="success" size="small" icon="el-icon-download"  @click="exportAll">导出考核汇总</el-button>
        </el-col>



<!--        <el-col :span="3" :pull="1" >-->
<!--          <download-excel-->
<!--            class = "export-excel-wrapper"-->
<!--            :data = "json_data"-->
<!--            :fields = "json_fields"-->
<!--            name = "律师考核汇总.xls">-->
<!--            &lt;!&ndash; 上面可以自定义自己的样式，还可以引用其他组件button &ndash;&gt;-->
<!--            <el-button type="success" size="small" icon="el-icon-download" >下载</el-button>-->
<!--          </download-excel>-->
<!--        </el-col>-->


          <!-- 年度选择框 -->
          <el-col :span="2" :pull="1" > 选择年度:  </el-col>
          <el-col :span="2" :pull="1">
            <el-select v-model="selectedYear" placeholder="请选择" @change="yearChange"  size="small">
              <el-option
                v-for="item in years"
                :key="item.id"
                :label="(item.startDate.substring(0,4)) + '年度'"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>

          <!-- 律师名称搜索框  -->
        <el-col :span="2"  > 律师名称:</el-col>
        <el-col :span="3" >
          <el-input
            placeholder="请输入"
            v-model="searchLawyerName"
            size="small"
          >
          </el-input>
        </el-col>

          <!-- 搜索按钮 -->
        <el-col :span="6" :push="1">
          <el-button  size="small" type="primary" @click="handleSearch" icon="el-icon-search">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>
        </el-col>
        </el-row>

      <!-- 多选表单 -->
      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        ref="multipleTable"
        :data="tableData"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
<!--        <el-table-column-->
<!--          type="selection"-->
<!--          width="55">-->
<!--        </el-table-column>-->

        <el-table-column
          align="center"
          prop="name"
          label="律师姓名"
          width="100">
        </el-table-column>

        <el-table-column
          prop="practiceno"
          align="center"
          label="执业证编号"
          width="200"


          >
        </el-table-column>


        <el-table-column
          align="center"
          prop="practicingYears"
          label="职业年限"
          width="100">
        </el-table-column>



        <el-table-column label="培训" align="center">
          <el-table-column
            align="center"
            prop="eightScore"
            label="职业道德学时"
            width="140">
          </el-table-column>

          <el-table-column
            align="center"
            prop="eightScoreStatus"
            label="达标状态"
            width="100">
          </el-table-column>

<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="otherScore"-->
<!--            label="导入培训学时"-->
<!--            width="120">-->
<!--          </el-table-column>-->


          <el-table-column
            align="center"
            prop="trainScore"
            label="其他培训学时"
            width="110">
          </el-table-column>


        <el-table-column
          align="center"
          prop="trainScoreStatus"
          label="达标状态"
          width="100">
        </el-table-column>

        </el-table-column>


        <el-table-column
          align="center"
          label="公益"
          width="150">


        <el-table-column
          align="center"
          prop="serviceScore"
          label="公益时长"
          width="100">
        </el-table-column>
        <el-table-column
          align="center"
          prop="serviceScoreStatus"
          label="达标状态"
          width="100">
        </el-table-column>

        </el-table-column>

        <el-table-column
          prop="dname"
          align="center"
          label="所在律所" width="200">
        </el-table-column>


        <el-table-column
          prop="phone"
          align="center"
          label="联系方式" width="180">
        </el-table-column>


        <el-table-column fixed="right" width="340" label="查看学时记录" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-more"
              @click="handleEightDetail(scope.row)"
            >职业道德</el-button
            >

          <el-button
            size="mini"
            type="info"
            icon="el-icon-more"
            @click="handleTrainDetail(scope.row)"
          >其他培训
          </el-button>
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-more"
            @click="handleServiceDetail(scope.row)"
          >公益学时
          </el-button>
          </template>
          </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>

    </div>
    <!--    8学时详情弹窗-->
    <el-dialog
      title="职业道德学时记录"
      align="center"
      :visible.sync="ifEightDetail"
      width="70%">

      <el-table
        max-height="500px"
        :data="eightRecord"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          width="50">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="日期"
          width="140"
          align="center">
        </el-table-column>

        <el-table-column
          prop="getWay"
          align="center"
          label="取得方式"
          width="140">
        </el-table-column>

        <el-table-column
          prop="detail"
          align="center"
          label="具体内容"
          >
        </el-table-column>

        <el-table-column
          prop="score"
          label="学时"
          width="100"
          align="center">
        </el-table-column>


      </el-table>
    </el-dialog>
    <!--    其他培训学时详情弹窗-->
    <el-dialog
      title="其他培训学时记录"
      align="center"
      :visible.sync="ifTrainDetail"
      width="70%">
      <el-table
        max-height="500px"
        :data="trainRecord"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          width="50">
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="日期"
          width="140"
          align="center">
        </el-table-column>

        <el-table-column
          prop="getWay"
          label="取得方式"
          align="center"
          width="140">
        </el-table-column>

        <el-table-column
          prop="detail"
          align="center"
          label="具体内容"
          >
        </el-table-column>

        <el-table-column
          prop="score"
          label="学时"
          width="100"

          align="center">
        </el-table-column>


      </el-table>
    </el-dialog>
    <!--    公益学时详情弹窗-->
    <el-dialog
      title="公益时长记录"
      align="center"
      :visible.sync="ifServiceDetail"
      width="70%">
      <el-table
        max-height="500px"
        :data="serviceRecord"
        :header-cell-style="{ color: '#333' }"
        :border=true
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          width="50">
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="日期"
          width="140"
          align="center">
        </el-table-column>

        <el-table-column
          prop="getWay"
          label="取得方式"
          align="center"
          width="140">
        </el-table-column>

        <el-table-column
          prop="detail"
          label="具体内容"
          align="center"
          >
        </el-table-column>

        <el-table-column
          prop="score"
          label="时长"
          width="100"
          align="center">
        </el-table-column>


      </el-table>
    </el-dialog>
    <!--    导入职业道德弹窗-->
    <el-dialog

      title="将要导入的职业道德学时"
      :visible.sync="importDialogVisible"
      :show-close="false"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="lawyerDialog"
    >
<!--      {{importData}}-->

        <el-table
          v-loading="importData_loading"
          element-loading-text="加载中"
          ref="multipleTable"
          :data="importData"
          max-height="400px"
          tooltip-effect="dark"
          style="width: 100%"
        >

          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            prop="name"
            label="律师姓名"
            width="120">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="dname"
            label="执政律所"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="practiceno"
            label="执业证号"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="eightScore"
            label="应算课时（职业道德学时）"
            show-overflow-tooltip>
          </el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleImportCancel">放弃导入</el-button>
            <el-button size="small" type="primary" @click="handleImportConfirm">确定导入</el-button>
          </span>



    </el-dialog>

    <!--    导入其他培训弹窗-->
    <el-dialog
      title="将要导入的其他培训学时"
      :visible.sync="importDialogVisible_other"
      :show-close="false"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="lawyerDialog"

    >
<!--      {{importData_other}}-->
      <el-table
        v-loading="importData_loading_other"
        element-loading-text="加载中"
        ref="multipleTable"
        :data="importData_other"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="400px"
      >

        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="律师姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="dname"
          label="执政律所"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="practiceno"
          label="执业证号"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="otherScore"
          label="应算课时(其他培训课时)"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
            <el-button size="small"  @click="handleImportCancel_other">放弃导入</el-button>
            <el-button size="small" type="primary" @click="handleImportConfirm_other">确定导入</el-button>
          </span>
    </el-dialog>
    <!--    导入其他培训弹窗-->
    <el-dialog
      title="将要导入的公益法律服务时长"
      :visible.sync="importDialogVisible_free"
      :show-close="false"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="lawyerDialog"

    >
      <!--      {{importData_other}}-->
      <el-table
        v-loading="importData_loading_free"
        element-loading-text="加载中"
        ref="multipleTable"
        :data="importData_free"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="400px"
      >

        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="律师姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="dname"
          label="执政律所"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="practiceno"
          label="执业证号"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="freeScore"
          label="公益法律服务时长"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
            <el-button size="small"  @click="handleImportCancel_free">放弃导入</el-button>
            <el-button size="small" type="primary" @click="handleImportConfirm_free">确定导入</el-button>
          </span>
    </el-dialog>

    <!--    导入失败的弹窗-->
    <el-dialog
      :visible.sync="unFindDialogVisible"
      :show-close="true"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="lawyerDialog"

    >
      <h3>{{unFindList.length}}位律师导入失败，请检查以下律师执业证号是否正确</h3>
      <el-table
        ref="multipleTable"
        :data="unFindList"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="400px"
      >

        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="律师姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="dname"
          label="执政律所"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="practiceno"
          label="执业证号"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>

<!--      <span slot="footer" class="dialog-footer">-->
<!--            <el-button size="small"  @click="handleImportCancel_other">放弃导入</el-button>-->
<!--            <el-button size="small" type="primary" @click="handleImportConfirm_other">确定导入</el-button>-->
<!--          </span>-->
    </el-dialog>


    <el-dialog
      title="导出考核汇总"
      align="center"
      :visible.sync="ifExport"
      width="50%">
      <h1>共查询到{{json_data.length}}条记录</h1>

      <br>

        <download-excel
          class = "export-excel-wrapper"
          :data = "json_data"
          :fields = "json_fields"
          :name = "exportName">
<!--          name = "律师考核汇总.xls">-->
          <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
          <el-button type="success" size="small" icon="el-icon-download" @click="ifExport=false">下载excel</el-button>
        </download-excel>


    </el-dialog>
  </div>
</template>

<script>
  import {formatDate} from '@/utils/date'
  import { request } from '@/network/network'
  import { transcript } from '@/utils/export'
  import XLSX from 'xlsx'

  export default {
    data() {
      return {
        loading: true,
        importData_loading:true,
        importData_loading_other:true,
        importData_loading_free:true,
        load_loading: true,
        // 加分记录
        eightRecord:[],
        trainRecord:[],
        serviceRecord:[],
        freeRecord:[],

        //导出excel绑定
        json_fields: transcript,
        json_data: [],

        fileTemp:[],
        importData:[],

        fileTemp_other:[],
        importData_other:[],

        fileTemp_free:[],
        importData_free:[],

        ifExport:false,
        importDialogVisible: false,
        importDialogVisible_other: false,
        importDialogVisible_free: false,

        unFindList: [],
        unFindDialogVisible: false,

        total: 0,
        currentPage: 0,
        years:"",
        selectedYear:"1",


        tableData: [],
        currentData: "",


        ifEightDetail: false,
        ifTrainDetail: false,
        ifServiceDetail: false,
        ifFreeDetail: false,

        searchLawyerName: '',
        searchMap:'',

        exportName:''



      }
    },
    methods: {
      exportAll(){
        var exportDate = new Date();
        var year = exportDate.getFullYear();
        var month = exportDate.getMonth() + 1;
        var day = exportDate.getDate();
        this.exportName = '律师考核汇总' + '(' + year + '-' + month+ '-' +day + ')' + '.xls'
        const loading = this.$loading({
          lock: true,
          text: '正在加载考核数据，请稍等',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.exportAssessments(this.selectedYear).then(
          res => {loading.close();
            this.ifExport = true
          })

      },
      //--------------------------导入职业道德培训课时
      handleChange(file, fileList) {
        this.fileTemp = file.raw;
        if (this.fileTemp) {
          this.import(this.fileTemp)
          this.importDialogVisible = true;
        }
      },
      // //超出最大上传文件数量时的处理方法
      // handleExceed() {
      //   this.$message({
      //     type: 'warning',
      //     message: '只能选择一个文件导入！'
      //   })
      //   return;
      // },
      // //移除文件的操作方法
      // handleRemove(file, fileList) {
      //   this.fileTemp = []
      // },

      async import(file) {
        if (!file) return
        let reader = await  new Promise(resolve=>{
          let fileReader = new FileReader()
          fileReader.readAsBinaryString(file);
          fileReader.onload = ev=>{resolve(ev.target.result)}
        })
        const worker = XLSX.read(reader, { type: 'binary' })
        console.log(worker)
        // 这个是将数据进行一步拼接
        //  worker.Sheets[worker.SheetNames[0]]
        // 将返回的数据转换为json对象的数据
        reader = XLSX.utils.sheet_to_json( worker.Sheets[worker.SheetNames[0]])
        let arr = []
        reader.map(v => {
                let obj = {}
                obj.name = v['姓名']
                obj.phone = v['手机号']
                obj.dname = v['职业律所']
                obj.practiceno = v['执业证号']
                obj.eightScore = v['应算课时']
                arr.push(obj)
        });
        this.importData = arr
        this.importData_loading = false
      },

      // 确定导入
      handleImportConfirm(){
        const loading = this.$loading({
          lock: true,
          text: '正在导入，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.loadScore(this.importData)
          .then((res) => {
            loading.close();
            this.load_loading = false
            if(res.data.length == 0){
              this.$message({
                message: "导入职业道德学时成功！",
                type: "success",
                duration: 2000,
              });
              this.$refs['upload'].clearFiles();
              this.getAssessments(this.selectedYear);
              this.importDialogVisible = false
            }
            else{

              this.$refs['upload'].clearFiles();
              this.getAssessments(this.selectedYear);
              this.importDialogVisible = false
              this.unFindList = res.data
              this.unFindDialogVisible = true
            }

          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: "发生错误，导入失败！",
              type: "error",
              duration: 1000,
            });
            this.$refs['upload'].clearFiles();
            this.getAssessments(this.selectedYear);
            this.importDialogVisible = false
          });






      },
      // 取消导入
      handleImportCancel(){
        this.importDialogVisible = false;
        this.fileTemp = []
        this.importData = []
        this.$refs['upload'].clearFiles();
      },

      //--------------------------导入其他培训课时

      handleChange_other(file, fileList) {
        this.fileTemp_other = file.raw;
        if (this.fileTemp_other) {
          this.import_other(this.fileTemp_other)
          this.importDialogVisible_other = true;
        }
      },
      handleChange_free(file, fileList) {
        this.fileTemp_other = file.raw;
        if (this.fileTemp_other) {
          this.import_free(this.fileTemp_other)
          this.importDialogVisible_free = true;
        }
      },

      async import_other(file) {
        if (!file) return
        let reader = await  new Promise(resolve=>{
          let fileReader = new FileReader()
          fileReader.readAsBinaryString(file);
          fileReader.onload = ev=>{resolve(ev.target.result)}
        })
        const worker = XLSX.read(reader, { type: 'binary' })

        // 这个是将数据进行一步拼接
        //  worker.Sheets[worker.SheetNames[0]]
        // 将返回的数据转换为json对象的数据
        reader = XLSX.utils.sheet_to_json( worker.Sheets[worker.SheetNames[0]])
        let arr = []
        reader.map(v => {
          let obj = {}
          obj.name = v['姓名']
          obj.phone = v['手机号']
          obj.dname = v['职业律所']
          obj.practiceno = v['执业证号']
          obj.otherScore = v['应算课时']
          arr.push(obj)
        });
        this.importData_other = arr
        this.importData_loading_other = false
      },

      async import_free(file) {
        if (!file) return
        let reader = await  new Promise(resolve=>{
          let fileReader = new FileReader()
          fileReader.readAsBinaryString(file);
          fileReader.onload = ev=>{resolve(ev.target.result)}
        })
        const worker = XLSX.read(reader, { type: 'binary' })

        // 这个是将数据进行一步拼接
        //  worker.Sheets[worker.SheetNames[0]]
        // 将返回的数据转换为json对象的数据
        reader = XLSX.utils.sheet_to_json( worker.Sheets[worker.SheetNames[0]])
        let arr = []
        reader.map(v => {
          let obj = {}
          obj.name = v['姓名']
          obj.phone = v['手机号']
          obj.dname = v['职业律所']
          obj.practiceno = v['执业证号']
          obj.freeScore = v['应算课时']
          arr.push(obj)
        });
        this.importData_free = arr
        this.importData_loading_free = false
      },

      // 确定导入
      handleImportConfirm_other(){
        const loading = this.$loading({
          lock: true,
          text: '正在导入，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.loadScore(this.importData_other)
          .then((res) => {
            loading.close();
            if(res.data.length == 0){
              this.$message({
                message: "导入培训学时成功！",
                type: "success",
                duration: 2000,
              });
              this.$refs['upload_other'].clearFiles();
              this.getAssessments(this.selectedYear);
              this.importDialogVisible_other = false
            }
            else{
              this.$refs['upload_other'].clearFiles();
              this.getAssessments(this.selectedYear);
              this.importDialogVisible_other = false
              this.unFindList = res.data
              this.unFindDialogVisible = true
            }
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: "发生错误，导入失败！",
              type: "error",
              duration: 1000,
            });
            this.$refs['upload_other'].clearFiles();
            this.getAssessments(this.selectedYear);
            this.importDialogVisible_other = false
          });
      },
      // 取消导入
      handleImportCancel_other(){
        this.importDialogVisible_other = false;
        this.fileTemp_other = []
        this.importData_other = []
        this.$refs['upload_other'].clearFiles();
      },

      handleImportConfirm_free(){
        const loading = this.$loading({
          lock: true,
          text: '正在导入，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.loadScore(this.importData_free)
          .then((res) => {
            loading.close();
            if(res.data.length == 0){
              this.$message({
                message: "导入公益服务时长成功！",
                type: "success",
                duration: 2000,
              });
              this.$refs['upload_free'].clearFiles();
              this.getAssessments(this.selectedYear);
              this.importDialogVisible_free = false
            }
            else{
              this.$refs['upload_other'].clearFiles();
              this.getAssessments(this.selectedYear);
              this.importDialogVisible_free = false
              this.unFindList = res.data
              this.unFindDialogVisible = true
            }
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: "发生错误，导入失败！",
              type: "error",
              duration: 1000,
            });
            this.$refs['upload_other'].clearFiles();
            this.getAssessments(this.selectedYear);
            this.importDialogVisible_free = false
          });
      },
      // 取消导入
      handleImportCancel_free(){
        this.importDialogVisible_free = false;
        this.fileTemp_free = []
        this.importData_free = []
        this.$refs['upload_other'].clearFiles();
      },

      yearChange(){
        this.loading = true
        this.getAssessments(this.selectedYear)

      },

      handleCurrentChange(val) {
        this.currentPage = val;
        this.loading = true
        this.getAssessments(this.selectedYear, this.searchMap, val)
      },
      //搜索按钮
      handleSearch(){
        this.searchMap = this.searchLawyerName
        this.getAssessments(this.selectedYear, this.searchMap)
      },
      clearInput(){
        this.searchLawyerName = ''
        this.searchMap = ''
        this.getAssessments(this.selectedYear)

      },
      //多选改变事件
      handleSelectionChange(){
      },
      //查看8学时详情
      handleEightDetail(row) {
        this.ifEightDetail = true;
        this.getEightRecord(row.uid, this.selectedYear)
      },
      //查看其他培训学时详情
      handleTrainDetail(row) {
        this.ifTrainDetail = true;
        this.getTrainRecord(row.uid, this.selectedYear)
      },
      //查看公益学时详情
      handleServiceDetail(row) {
        this.ifServiceDetail = true;
        this.getServiceRecord(row.uid, this.selectedYear)
      },

      getYears() {
        return request({
          url: 'year/years',
          method: 'get',
          params: {}
        })
          .then(res=>{
            this.years = res.data
            let current = 0;
            // for (const re of res) {
            //
            // }
            for (let year of this.years) {
              if(year.id > current){
                current = year.id
              }
            }
            this.selectedYear = current
            // console.log(this.selectedYear)
          })
      },

      getAssessments(yid, name, page){
        return request({
          url: 'assessment/assessments',
          method: 'get',
          params: {
            yid,
            name,
            page
          }
        }).then(res => {
          this.total = res.data.total
          // console.log(res.data.list)
          let temp = res.data.list
          this.tableData = temp.map(item => {
            if(item.eightScore == null){item.eightScore = 0}
            if(item.aeightScore == null){item.aeightScore = 0}
            if(item.teightScore == null){item.teightScore = 0}

            if(item.trainScore == null){item.trainScore = 0}
            if(item.atrainScore == null){item.atrainScore = 0}
            if(item.otherScore == null){item.otherScore = 0}
            if(item.serviceScore == null){item.serviceScore = 0}
            if(item.aserviceScore == null){item.aserviceScore = 0}

            item.trainScore = item.atrainScore + item.otherScore + item.trainScore

            item.eightScore = item.eightScore + item.aeightScore + item.teightScore

            item.serviceScore = item.serviceScore + item.aserviceScore

            // (item.serviceScore>=50)?item.serviceScoreStatus="达标":item.serviceScoreStatus="未达标";
            item.serviceScoreStatus= (item.serviceScore >= 50)? "达标":"未达标";

            // console.log(item.serviceScoreStatus)
            if(item.practicingYears <= 9){
              (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
              (item.eightScore + item.trainScore>=40)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
            }
            if(item.practicingYears >= 10 && item.practicingYears <=19){
              (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
              (item.eightScore + item.trainScore>=20)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
            }
            if(item.practicingYears >=20){
              (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
              item.trainScoreStatus="达标";
            }
            return item
          })
          this.loading = false
          this.json_data = this.tableData
        })
      },
      exportAssessments(yid){
        return request({
          url: 'assessment/allAssessments',
          method: 'get',
          params: {
            yid,
          }
        }).then(res => {

          let temp = res.data
          this.json_data = temp.map(item => {
            if(item.eightScore == null){item.eightScore = 0}
            if(item.aeightScore == null){item.aeightScore = 0}
            if(item.teightScore == null){item.teightScore = 0}

            if(item.trainScore == null){item.trainScore = 0}
            if(item.atrainScore == null){item.atrainScore = 0}
            if(item.otherScore == null){item.otherScore = 0}

            if(item.serviceScore == null){item.serviceScore = 0}

            item.trainScore = item.atrainScore + item.otherScore + item.trainScore

            item.eightScore = item.eightScore + item.aeightScore + item.teightScore

            // (item.serviceScore>=50)?item.serviceScoreStatus="达标":item.serviceScoreStatus="未达标";
            item.serviceScoreStatus= (item.serviceScore >= 50)? "达标":"未达标";

            // console.log(item.serviceScoreStatus)
            if(item.practicingYears <= 9){
              (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
              (item.eightScore + item.trainScore>=40)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
            }
            if(item.practicingYears >= 10 && item.practicingYears <=19){
              (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
              (item.eightScore + item.trainScore>=20)?item.trainScoreStatus="达标":item.trainScoreStatus="未达标";
            }
            if(item.practicingYears >=20){
              (item.eightScore>=8)?item.eightScoreStatus="达标":item.eightScoreStatus="未达标";
              item.trainScoreStatus="达标";
            }
            return item
          })

        })
      },
      loadScore(data){
        return request({
          url: 'dynamic-score/loadScore',
          method: 'post',
          data
        })
      },

      //查职业道德记录
      getEightRecord(uid, yid){
        return request({
          url: 'assessment/eightScoreRecord',
          method: 'get',
          params: {
            uid,
            yid,
          }
        }).then(res => {
          this.eightRecord = res.data

        })
      },
      //查其他培训记录
      getTrainRecord(uid, yid){
        return request({
          url: 'assessment/trainScoreRecord',
          method: 'get',
          params: {
            uid,
            yid,
          }
        }).then(res => {
          this.trainRecord = res.data
        })
      },
      //查公益记录
      getServiceRecord(uid, yid){
        return request({
          url: 'assessment/serviceScoreRecord',
          method: 'get',
          params: {
            uid,
            yid,
          }
        }).then(res => {
          this.serviceRecord = res.data


        })

      }
    },
    created() {
      this.getYears().then(res => {
        this.getAssessments(this.selectedYear)

      })



    }

  }

</script>
